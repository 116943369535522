@mixin hcQuickToolBtn() {
  width: 45px;
  height: 45px;
  background-color: var(--hc-accent5);
  border: 1px solid var(--hc-accent2);
  border-top: none;
  @include flex-center-center();
  transition: background-color 200ms ease;

  @media (max-height: 660px) {
    width: 35px;
    height: 35px;
  }

  &:first-child {
    border-top: 1px solid var(--hc-accent2);
  }

  &:active,
  &.selected {
    background-color: var(--hc-accent2);

    icon svg {
      fill: var(--hc-accent1);
    }
  }

  icon {
    width: 50%;
    height: 50%;

    svg {
      fill: black;
    }
  }
}
