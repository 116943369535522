@import '../../../styles/master';

:root {
  --hc-expanding-menu-height: 131px; // FRAGILE -- based on "natural" height for main nav buttons and sub-menu buttons
}

$hcExpandingMenuHeaderHeight: 40px;

hc-expanding-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--hc-expanding-menu-height);
  transition: height 200ms ease;

  &.expanded {
    height: 100%;

    .expanding-menu-body {
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: block;
      }
    }

    .mobile-menu-buttons-container {
      white-space: normal;
      @include hoverVisibilityScrollbarOverflowY();

      button {
        width: 100%;
        height: auto;
        padding: 1rem;
      }

      button + button {
        margin-left: 0;
        margin-top: 0.5rem;
      }
    }
  }

  &.home-alt {
    .expanding-menu-header {
      .home-btn {
        background-color: transparent;
        icon {
          fill: var(--hc-mobile-menu-title-foreground);
        }
      }
      h4 {
        color: var(--hc-mobile-menu-title-foreground);
      }
    }
  }

  &:not(.expanded) {
    .mobile-menu-buttons-container button {
      max-width: 80vw;
    }
  }

  .expanding-menu-header {
    display: flex;
    align-items: center;
    background-color: var(--hc-mobile-menu-title-background);
    color: var(--hc-mobile-menu-title-foreground);
    box-shadow: var(--box-shadow);
    height: $hcExpandingMenuHeaderHeight;
    flex: 0 0 auto;
    z-index: 1;

    @include media('>=desktop') {
      display: none;
    }

    button {
      @include flex-center-center();
      aspect-ratio: 1;
      height: 100%;

      icon {
        width: 20px;
        height: 20px;
      }
    }

    .home-btn {
      margin-right: 0.5rem;
      background-color: var(--hc-primary-button-background);
      icon {
        fill: var(--hc-primary-button-foreground);
      }
    }

    .back-btn {
      icon {
        fill: var(--hc-mobile-menu-title-foreground);
        display: inline-flex;

        &::before {
          content: '';
          border-left: 1px solid var(--hc-mobile-menu-title-foreground);
          padding-left: 0.5em;
        }

        svg {
          flex-shrink: 0;
        }
      }
    }

    h4 {
      text-transform: uppercase;
      font-size: 14px;
      line-height: normal;
    }

    .expand-collapse-btn {
      margin-left: auto;
      background-color: black;

      icon {
        animation: fadeIn 150ms linear;
      }
    }
  }

  .expanding-menu-body {
    flex: 1 1 0;
    background-color: var(--hc-background1);
    color: var(--hc-foreground1);
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .scroll-right-shadow {
    @include absolute(top $hcExpandingMenuHeaderHeight right -1px bottom 0);
    width: 1px;
    z-index: 2;
    pointer-events: none;
    box-shadow: 2px 4px 12px 3px rgba(0, 0, 0, 0.65);
    animation: fadeIn 150ms ease;
  }
  .scroll-left-shadow {
    @include absolute(top $hcExpandingMenuHeaderHeight left -1px bottom 0);
    width: 1px;
    z-index: 2;
    pointer-events: none;
    box-shadow: 2px 4px 12px 3px rgba(0, 0, 0, 0.65);
    animation: fadeIn 150ms ease;
  }

  .mobile-menu-buttons-container {
    white-space: nowrap;
    height: 100%;
    padding: 1rem;
    background-color: var(--hc-background1);
    color: var(--hc-foreground1);

    & > button {
      padding: 1rem 1.5rem;
      white-space: nowrap;
      background-color: var(--hc-accent1);
      color: #fff;
      border-radius: 8px;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      & + button {
        margin-left: 0.95rem;
      }
    }
  }
}
