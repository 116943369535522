@import '../../styles/master';

home-configurator-root {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  .grid-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto auto 1fr;

    hc-header {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      z-index: 6;
    }
    hc-module-nav {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      z-index: 5;
    }
    hc-pricing-outline {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      z-index: 7;
    }
    .main-content {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }

    // 1366px -- medium desktop
    @include media('<=desktopSmall', '>desktop') {
      .main-content-side-menu {
        width: 35vw;
      }

      hc-module-nav {
        grid-column: 1 / 3;
      }
      hc-pricing-outline {
        grid-row: 1 / 2;

        .monthly-payment,
        .price-total {
          padding: 0.5rem;
        }
        .next-section {
          display: none;
        }
      }
    }

    @include media('<desktop') {
      hc-module-nav {
        grid-area: unset;
      }
    }

    @include media('>=tablet', '<desktop') {
      grid-template-columns: 100vw;
      grid-template-rows: auto 1fr auto;

      hc-header {
        grid-column: 1;
        grid-row: 1 / 2;
      }
      hc-pricing-outline {
        grid-column: 1;
        grid-row: 3 / 4;
      }
      .main-content {
        grid-column: 1;
        grid-row: 2 / 3;
      }
    }

    @include media('<tablet') {
      grid-template-columns: 100vw;
      grid-template-rows: auto 1fr auto;

      hc-header {
        grid-column: 1;
        grid-row: 1 / 2;
      }
      hc-pricing-outline {
        grid-column: 1;
        grid-row: 3 / 4;
      }
      .main-content {
        grid-column: 1;
        grid-row: 2 / 3;
      }
    }
  }
}
