@import '../../../styles/master';

hc-nav-blocker {
  display: block;
  @include absolute(top 0 right 0 left 0);
  bottom: var(--hc-expanding-menu-height);
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  @include flex-center-center();
  animation: fadeIn 150ms ease-in-out;
}
