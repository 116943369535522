@import '../../styles/master';
@import './hc-mixins';

home-configurator-root {
  --desktop-side-menu-width: 25vw;
  --mobile-pricing-outline-height: 70px;

  // HACK -- temp fixes for not having this color settings setup
  --modal-success-background-alpha-high: #008000c5;
  --modal-success-icon-foreground-alpha-medium: #ffffff77;
  --modal-alert-background-alpha-high: #ff0000c4;
  --modal-alert-icon-foreground-alpha-medium: #ffffff77;

  .main-content {
    overflow: hidden;
    position: relative;

    router-outlet + * {
      width: 100%;
      height: 100%;

      // I think this is safe to apply?... should always be the main component which will need this padding for mobile expanding menus
      @include media('<desktop') {
        padding-bottom: var(--hc-expanding-menu-height);
      }
    }

    hc-expanding-menu {
      @include absolute(bottom 0);
      z-index: 3;
    }

    .primary-container {
      flex: 1 1 auto;
      height: 100%;
      position: relative;
    }
  }

  .main-content-side-menu {
    flex: 0 0 auto;
    width: 25vw;
    background-color: var(--hc-background1);
    color: var(--hc-foreground1);
    box-shadow: var(--box-shadow);
    height: 100%;
    @include hoverVisibilityScrollbarOverflowY();
    z-index: 1;

    header.ih-header {
      background-color: var(--inventory-home-color);
      color: var(--inventory-home-foreground);
    }

    .menu-section {
      padding: 1.5rem;
      border-bottom: 1px solid var(--hc-accent2);

      & > * + * {
        margin-top: 1rem;
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        color: var(--hc-accent1);
        text-transform: uppercase;
      }
      p {
        line-height: 1.25;
      }

      & > button {
        width: 100%;
      }
    }
  }

  .phone-landscape-blocker {
    @include fixed(top 0 right 0 bottom 0 left 0);
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.9);
    color: #000;
    font-weight: bold;
    padding: 1rem;
    @include flex-center-center();
    display: none;

    icon,
    ml-icon {
      @include sameWidthHeight(2rem);
      margin-right: 1rem;
      fill: #000;
    }

    @include media('<tablet', 'landscape') {
      display: flex;
    }
  }
}

// use hc-styles for base things like buttons which can also be used outside home-configurator-root like in mat-dialog and cdk-overlay-container

.hc-styles {
  // variable color names likely to change - need direction on settings from Dekin

  --hc-accent2: #b3b3b3;
  --hc-accent3: #ffffff69;
  --hc-accent4: #f5f5f5;
  --hc-accent5: #fafafaeb;
  --hc-accent6: #999999;
  --hc-accent7: #f2f2f2;

  button.hc-btn {
    padding: 0.75rem 1.5rem;
    border-radius: var(--hc-button-border-radius);
    font-size: 14px;
    transition: all linear 150ms;
    transition-property: background-color, color;
    @include flex-center-center();
    text-transform: none;
    line-height: 1;
    font-weight: bold;
    box-shadow: var(--hc-button-box-shadow);

    & > icon,
    ml-icon {
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      margin-right: 0.5rem;
      transition: fill linear 150ms;
    }

    &.icon-only {
      border-radius: 50%;
      padding: 0.5rem;
      flex-shrink: 0;

      icon,
      ml-icon {
        margin-right: 0;
        @include sameWidthHeight(1.25em);
      }
    }

    &.full-width {
      width: 100%;
    }

    &.primary {
      background-color: var(--hc-primary-button-background);
      color: var(--hc-primary-button-foreground);

      & > icon,
      ml-icon {
        fill: var(--hc-primary-button-foreground);
      }

      &:hover {
        background-color: var(
          --hc-primary-button-hover-selected-background,
          var(--hc-primary-button-background-darker)
        );
        color: var(
          --hc-primary-button-hover-selected-foreground,
          var(--hc-primary-button-foreground-alpha-medium)
        );

        & > icon,
        ml-icon {
          fill: var(
            --hc-primary-button-hover-selected-foreground,
            var(--hc-primary-button-foreground-alpha-medium)
          );
        }
      }
    }

    &.secondary {
      background-color: var(--hc-secondary-button-background);
      color: var(--hc-secondary-button-foreground);

      & > icon,
      ml-icon {
        fill: var(--hc-secondary-button-foreground);
      }

      &:hover {
        background-color: var(
          --hc-secondary-button-hover-selected-background,
          var(--hc-secondary-button-background-darker)
        );
        color: var(
          --hc-secondary-button-hover-selected-foreground,
          var(--hc-secondary-button-foreground-alpha-medium)
        );

        & > icon,
        ml-icon {
          fill: var(
            --hc-secondary-button-hover-selected-foreground,
            var(--hc-secondary-button-foreground-alpha-medium)
          );
        }
      }
    }

    &.success {
      background-color: var(--hc-success-background);
      color: var(--hc-success-foreground);

      &:hover {
        background-color: var(--hc-success-background-darker);

        & > icon,
        ml-icon {
          fill: var(--hc-success-foreground-alpha-medium);
        }
      }

      & > icon,
      ml-icon {
        fill: var(--hc-success-foreground);
      }
    }

    &.negative {
      background-color: var(--hc-negative-button-background);
      color: var(--hc-negative-button-foreground);

      &:hover {
        background-color: var(--hc-negative-button-background-darker);

        & > icon,
        ml-icon {
          fill: var(--hc-negative-button-foreground-alpha-medium);
        }
      }

      & > icon,
      ml-icon {
        fill: var(--hc-negative-button-foreground);
      }
    }

    &.locked {
      background-color: var(--inventory-home-color);
      color: var(--inventory-home-foreground);
      pointer-events: none;

      & > icon,
      ml-icon {
        fill: var(--inventory-home-foreground);
      }
    }

    &.underline {
      box-shadow: none;
      text-decoration: underline;
      color: var(--hc-foreground1);
      background-color: transparent;
      padding: 0;
      text-align: left;

      &:hover {
        color: var(--hc-foreground1-alpha-medium);
      }
    }
  }

  .tools-container {
    @include absolute(right 2rem bottom 2rem);
    display: inline-flex;
    flex-direction: column;

    &.measure-active {
      z-index: 5;
    }

    scale-control {
      margin-top: 2rem;

      @include media('<tablet') {
        display: none;
      }
    }

    & > button {
      @include hcQuickToolBtn();
    }
  }

  scale-control {
    button {
      background-color: var(--hc-accent5);
    }

    @media (max-height: 660px) {
      width: 35px;

      button,
      #scale-display {
        height: 35px;
      }
    }
  }

  expanding-quick-menu {
    icon-button button {
      box-shadow: none;
      padding: 0;
      border-radius: 0;
      @include hcQuickToolBtn();
    }
  }

  &.mat-mdc-select-panel {
    max-height: 80vh;
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    .mat-option-text {
      display: flex;
      align-items: center;
    }
  }

  accordion .accordion-container {
    box-shadow: none;
    border-bottom: 1px solid var(--hc-foreground2-alpha-low);
    background-color: var(--hc-background1);
    color: var(--hc-foreground1);

    &.open {
      .accordion-header {
        background-color: var(--hc-accent1);
        color: #fff;

        .toggler icon,
        ml-iconsvg {
          fill: #fff;
        }
      }
    }

    .accordion-header {
      padding: 1.8rem;

      @include media('<tablet') {
        padding: 1.25rem;
      }

      h2 {
        transition: padding 150ms ease;
        position: relative;

        &.padding-left {
          padding-left: 2em;
        }
      }

      .toggler icon,
      ml-iconsvg {
        fill: var(--hc-foreground1);
      }
    }

    .accordion-body {
      background-color: var(--hc-accordion-body-background);
    }

    .body-content {
      padding: 0;
    }
  }

  .bubble {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: var(--hc-success-background);
    color: var(--hc-success-foreground);
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.25em;

    &.warning {
      background-color: var(--hc-alert-background);
      color: var(--hc-alert-foreground);

      icon,
      ml-icon {
        fill: var(--hc-alert-foreground);
      }
    }

    &.locked {
      background-color: var(--inventory-home-color);

      & > icon,
      ml-icon {
        fill: var(--inventory-home-foreground);
      }
    }

    icon,
    ml-icon {
      width: 55%;
      height: 55%;
      margin: 0;
      fill: var(--hc-success-foreground);
    }
  }
}

// applied to MatDialog
.fullscreen-overlay {
  max-width: none !important; // Angular Material applies a default inline
  width: 100%;
  height: 100%;

  .mat-mdc-dialog-container {
    padding: 0;
    @include hoverVisibilityScrollbarOverflowY();
  }
}

.mat-mdc-select-panel {
  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
    background-color: var(--hc-background1-darker);
    span {
      color: var(--hc-foreground1);
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-select {
  &.mat-mdc-select {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-mdc-select-value-text,
    .mat-select-placeholder {
      color: var(--hc-foreground1);
    }
  }
  &:focus {
    outline: 2px solid var(--hc-foreground2);
  }
}

.mat-mdc-option {
  background-color: var(--hc-background1);
  font-family: var(--primaryfont);
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  &:hover,
  &:hover:not(.mat-option-disabled) {
    background-color: var(--hc-background1-darker);
    span {
      color: var(--hc-foreground1);
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.mat-active {
    background-color: var(--hc-background1-darker);
    span {
      color: var(--hc-foreground1);
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    color: var(--hc-foreground1);
  }
}

body .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: var(--hc-background2-alpha-high);
  --mdc-plain-tooltip-supporting-text-color: var(--hc-foreground2);

  background-color: transparent;
}
