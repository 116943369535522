@import '../../../styles/master';

hc-module-nav {
  display: flex;
  align-items: flex-end;
  box-shadow: var(--box-shadow);
  @include sameWidthHeight(100%);

  nav {
    display: block;
    @include sameWidthHeight(100%);

    ol {
      height: 100%;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: stretch;

      li {
        flex-grow: 1;
        flex-basis: 0;
        position: relative;

        .lock-block {
          @include absolute(top 0 right 0 bottom 0 left 0);
          background-color: rgba(0, 0, 0, 0.65);
          @include flex-center-center();
          z-index: 3;

          icon {
            fill: #fff;
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }

    .module-btn {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      background-color: var(--hc-navigation-module-background);
      color: var(--hc-navigation-module-foreground);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 0.5px solid var(--hc-navigation-module-foreground-alpha-low);
      border-right: 0.5px solid var(--hc-navigation-module-foreground-alpha-low);
      transition: background-color 150ms linear;

      &:hover,
      &.selected {
        background-color: var(--hc-navigation-module-background-darker);
      }

      .top-section {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem 1rem 0;
        position: relative;
        flex-shrink: 0;

        .bubble {
          width: 20px;
          height: 20px;
          border: 1px solid var(--hc-success-background);
          margin-right: 8px;
          transition: all 150ms linear;

          &.empty {
            background-color: transparent;
            border: 1px solid var(--hc-navigation-module-foreground-alpha-medium);
          }

          &.warning {
            border: 1px solid var(--hc-alert-background);
          }

          &.locked {
            border: 1px solid var(--inventory-home-color);
          }
        }

        p {
          font-weight: normal;
          font-size: 12px;
          text-align: left;
        }
      }

      .bottom-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1em;
        flex-grow: 1;
        font-size: 16px;

        h3 {
          font-weight: bold;
          padding-right: 0.5em;
          line-height: 1.1;
          text-transform: uppercase;
          letter-spacing: 0;
          text-align: left;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .summary-btn {
      width: 1.8em;
      height: 1.8em;
      margin-left: 1em;
      background-color: var(--hc-navigation-module-foreground);
      border-radius: 50%;
      @include flex-center-center();
      flex-shrink: 0;
      box-shadow: var(--hc-button-box-shadow);
      transform: translateY(0);
      transition: transform 200ms linear; // timing matches module-summary slide down
      z-index: 3;

      &.open {
        transform: translateY(100%);
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      icon {
        animation: fadeIn 200ms ease-in-out;
        width: 45%;
        height: 45%;
        color: var(--hc-navigation-module-background);
        fill: var(--hc-navigation-module-background);
      }
    }

    hc-module-summary {
      @include absolute(top 100%);
      z-index: 1;
      box-shadow: inset 0px 12px 8px -8px rgba(0,0,0,0.2), var(--box-shadow);
    }
  }

  @include media('<desktop') {
    box-shadow: 0 -1px 7px #95989a;

    nav {
      hc-module-summary {
        display: none;
      }
      .summary-btn.open {
        transform: none;
      }

      ol li {
        flex-basis: 30vw;
        white-space: nowrap;
        max-width: 80vw;

        .bottom-section {
          padding-top: 0;

          h3 {
            /*text-decoration: underline;*/
          }
        }
      }
    }
  }

  @include media('<=844px', 'landscape') {
    nav .module-btn {
      .top-section {
        display: none;
      }
      .bottom-section {
        font-size: 10px;
      }
    }
  }
}

hc-expanding-menu {
  &.expanded {
    box-shadow: none;

    nav ol {
      flex-direction: column;

      li {
        flex-basis: 0;
        flex-grow: 0;
        white-space: normal;
        max-width: 100%;
      }

      li:last-of-type {

        .bottom-section {
          padding-top: 0.8em;
          padding-bottom: 1.3em;
        }

      }

      .module-btn {
        border-bottom: 1px solid var(--hc-accent4);

        .bottom-section {
          padding-top: 0.5em;
        }

      }
    }
  }

  &:not(.expanded) {

    .module-btn {
      
      .bottom-section {
        padding-top: 0.6em;
      }

    }

  }
}
