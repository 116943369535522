@import '../../../styles/master';

hc-header {
  display: flex;
  align-items: center;
  max-height: 100%;
  background-color: var(--hc-background1);
  color: var(--hc-foreground1);

  @include media('>=desktop') {
    min-height: 50px;
  }

  & > * + * {
    margin-left: 1rem;
  }

  .block-one,
  .block-two {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .block-one {
    .saved-builds-btn, .clear-session-btn {
      margin-right: 1rem;

      @include media('>=desktop') {
        display: none;
      }
    }

    .clear-session-btn {
      margin-left: auto;
    }

    .close-btn {
      display: none;
    }
  }

  .block-two {
    padding: 1rem 0;
    padding-right: 1rem;
    flex-grow: 1;
    justify-content: flex-end;

    .saved-builds-btn, .clear-session-btn {
      white-space: nowrap;
    }

    .clear-session-btn {
      margin-right: 1rem;
    }
  }

  .saved-builds-btn.hc-btn > ml-icon {
    width: 1.5em;
  }

  .app-title {
    height: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 1.35rem;

    img {
      height: 3rem;
      max-width: 160px;
      margin-right: 1rem;
      object-fit: contain;
      object-position: center;

      &.secondary {
        max-height: 5rem;
        margin-right: 0;
      }
    }

    b {
      color: var(--hc-accent1);

      &.inventory-home {
        color: var(--inventory-home-color);
      }
    }
  }

  .fp-title-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: 1rem;
    padding: 0 1rem;
    border-left: 1px solid var(--hc-foreground1-alpha-low);
    border-right: 1px solid var(--hc-foreground1-alpha-low);

    ml-icon[name='floorplan'] {
      height: 45px;
      aspect-ratio: 1 / 1;
      fill: var(--hc-accent1);
      margin-right: 0.5rem;
    }

    .text-container {
      display: flex;
      flex-direction: column;

      .subtitle {
        margin-bottom: 0.5rem;
        white-space: nowrap;
      }
    }
  }

  .subtitle {
    font-weight: normal;
    color: var(--hc-accent1);
    text-transform: uppercase;
    font-size: 14px;
  }

  .fp-name {
    font-weight: normal;
    max-width: 235px;
    max-height: 55px;
    display: flex;
    align-items: center;

    button.hc-btn {
      font-size: 16px;
    }
  }

  @include media('<desktop') {
    flex-direction: column;
    height: auto;

    & > * + * {
      margin-left: 0;
    }

    .block-one {
      background-color: var(--hc-background1);
      color: var(--hc-foreground1);
      width: 100%;
      height: 50px;
      box-shadow: var(--box-shadow);
      z-index: 0;

      .app-title {
        border-right: none;
        font-size: 14px;

        img {
          height: 2rem;
          max-width: 80px;
        }
      }

      .saved-builds-btn, .clear-session-btn {
        margin-right: 0.65rem;
        padding: 0.65rem 1rem;
      }

      .close-btn {
        @include flex-center-center();
        background-color: var(--hc-background1);
        height: 100%;
        aspect-ratio: 1 / 1;
        box-shadow: -4px -2px 5px #00000026;

        ml-icon {
          fill: #000;
          @include sameWidthHeight(1rem);
        }
      }
    }

    .block-two {
      box-shadow: var(--box-shadow);
      width: 100%;
      padding: 0 1rem;
      height: 50px;
      justify-content: flex-start;

      & > * + * {
        margin-left: 1rem;
      }

      .saved-builds-btn, .clear-session-btn {
        display: none;
      }

      .fp-title-container {
        border: none;
        margin: 0;
        padding: 0;

        ml-icon[name='floorplan'] {
          @include sameWidthHeight(1rem);
        }

        .text-container {
          flex-direction: row;
          align-items: center;

          .subtitle {
            margin-bottom: 0;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  @include media('<tablet', 'portrait') {
    .app-title {
      width: 100%;
    }

    .block-one {
      .saved-builds-btn.hc-btn, .clear-session-btn.hc-btn {
        border-radius: 50%;
        padding: 0.65rem;
        margin-right: 0.65rem;

        & > ml-icon {
          @include sameWidthHeight(1rem);
          margin-right: 0;
        }

        &:hover {
          background-color: var(
            --hc-secondary-button-hover-selected-background,
            var(--hc-secondary-button-background-darker)
          );
          color: var(
            --hc-secondary-button-hover-selected-foreground,
            var(--hc-secondary-button-foreground)
          );

          & > ml-icon {
            fill: var(
              --hc-secondary-button-hover-selected-foreground,
              var(--hc-secondary-button-foreground)
            );
          }
        }

        .label {
          display: none;
        }
      }
    }

    .block-two .fp-title-container {
      ml-icon[name='floorplan'] {
        padding-right: 0.5rem;
        width: 1.85rem;
        border-right: 1px solid var(--hc-foreground1-alpha-low);
      }

      .subtitle {
        display: none;
      }
    }
  }
}
