@import '../../../../styles/master';

hc-module-summary {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: var(--hc-background1);
  color: var(--hc-foreground1);
  text-align: center;
  position: relative;

  .close-btn {
    @include absolute(top 0.75rem right 0.75rem);
    width: auto;
    margin-top: 0;

    icon {
      width: 1rem;
      height: 1rem;
    }
  }

  p {
    // temp
    margin-top: 3rem;
  }

  .summary-section {
    width: 100%;
    margin-top: 1rem;

    & + .summary-section {
      padding-top: 1rem;
      border-top: 1px solid var(--hc-accent2);
    }

    table {
      width: 100%;
      text-align: left;

      th {
        font-weight: normal;
        color: var(--hc-accent1);
        text-transform: uppercase;
        font-size: 14px;
        padding: 0.5rem 0;

        &:last-child {
          text-align: right;
        }
      }
      td {
        vertical-align: top;

        &:last-child {
          text-align: right;
        }

        h2 {
          font-size: 16px;
          word-break: break-all;
        }

        h4 {
          font-size: 16px;
          margin-top: 0.25em;
          font-weight: normal;
          font-style: italic;
        }
      }
    }
  }

  button {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
    text-transform: uppercase;
  }
}

.module-summary-modal .mat-mdc-dialog-container {
  padding: 0;

  hc-module-summary {
    padding-top: 2rem;
  }
}
