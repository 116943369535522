@import '../../../styles/master';

hc-pricing-outline {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--hc-background2);
  color: var(--hc-foreground2);
  box-shadow: var(--box-shadow);

  .price-total {
    padding: 1rem;
    flex: 1 1 auto;

    icon {
      @include sameWidthHeight(1em);
      margin-left: 0.25rem;
      fill: var(--hc-accent1);
      flex-shrink: 0;
      vertical-align: middle;
    }
  }

  .money-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    icon[name='calculator'] {
      display: none;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: normal;
    color: var(--hc-accent1);
    text-transform: uppercase;
  }

  .dollar-amount {
    font-weight: bold;

    &.larger {
      font-size: 2rem;
    }
  }

  .monthly-payment {
    flex: 1 1 auto;
    position: relative;
    padding: 1rem;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--hc-background1-alpha-medium);
    border: 1px solid var(--hc-foreground2-alpha-low);
    border-radius: 8px;
  }

  .full-label {
    display: inline-block;
  }
  .short-label {
    display: none;
  }

  @include media('>=desktop') {
    .monthly-payment .calc-btn:hover + .mortgage-popup,
    .mortgage-popup:hover {
      visibility: visible;
      transition: all 0.2s ease 0s;
    }

    .price-total .dollar-amount {
      font-size: 2rem;
    }
  }

  .mortgage-popup {
    display: block;
    visibility: hidden;
    animation: fadeIn ease-out 200ms;
    background-color: var(--hc-background1);
    color: var(--hc-foreground1);
    border-radius: 8px;
    padding: 1rem;
    @include absolute(top 55px right 10px);
    max-width: 280px;
    z-index: 5;
    box-shadow: 0 1px 5px #9f9f9f99;

    transition: all 0.2s ease 0.5s;

    * + * {
      margin-top: 0.5rem;
    }

    p {
      font-size: 11px;

      &.larger {
        font-size: 14px;
      }
    }

    button {
      width: 100%;
      text-align: center;
    }
  }

  .reserve-btn.hc-btn {
    width: 90%;
    margin: 0.25rem auto 1rem;

    @include media('>tablet') {
      display: none;
    }
  }

  @include media('<desktopSmall') {
    flex-direction: row;
    align-items: center;

    .price-total,
    .monthly-payment {
      justify-content: flex-start;
    }

    .monthly-payment {
      padding: 0.5rem 1rem;
      flex: 0 1 auto;
    }

    .price-total {
      padding: 0 1rem;

      .dollar-amount {
        font-size: 16px;
      }
    }

    .full-label {
      display: none;
    }
    .short-label {
      display: inline-block;
    }
  }

  @include media('<desktop') {
    .monthly-payment {
      .calc-btn {
        margin-left: 1rem;
      }

      .mortgage-popup {
        top: auto;
        bottom: 55px;
        max-width: 300px;
      }
    }

    .price-total .dollar-amount {
      font-size: 16px;
    }
  }

  @include media('<desktop', '>=tablet') {
    .money-container {
      flex-direction: row;
      align-items: center;

      .dollar-amount {
        margin-left: 0.5rem;
      }
    }
  }

  @include media('<tablet') {
    flex-wrap: wrap;

    .hc-btn.calc-btn {
      display: none;
    }

    .money-container {
      icon[name='calculator'] {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.5em;
        @include sameWidthHeight(1em);
        fill: var(--hc-accent1);
      }
    }

    .monthly-payment .money-container .dollar-amount span {
      text-decoration: underline;
    }
  }
}
